import * as emailjs from 'emailjs-com';
import { EMAIL_JS_ID, EMAIL_SERVICE_ID, EMAIL_TEMPLATE } from 'babel-dotenv';

export function init() {
  emailjs.init(EMAIL_JS_ID);
}

export async function sendForm(form) {
  hideElement('form-before');
  showElement('form-loading');
  await emailjs.sendForm(EMAIL_SERVICE_ID, EMAIL_TEMPLATE, form);
  hideElement('form-loading');
  showElement('form-after');
}

export function showElement(elementID) {
  document.getElementById(elementID).style.display = 'block';
}

export function hideElement(elementID) {
  document.getElementById(elementID).style.display = 'none';
}
