import { init, sendForm } from './utils';
import '../styles/index.scss';
import '../styles/spinner.scss';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

(async () => {
  init();

  const form = document.getElementById('application-form');
  const slider = document.getElementById('amount');
  const output = document.getElementById('amountset');

  form.addEventListener('submit', async event => {
    event.preventDefault();
    await sendForm(form);
  });

  output.innerHTML = formatter.format(slider.value);

  slider.oninput = function() {
    output.innerHTML = formatter.format(slider.value);
  };
})();
